const genresData = [
  'Pop',
  'Rock',
  'Jazz',
  'Classical',
  'Blues',
  'Country',
  'House-Music',
  'Deep-House',
  'Progressive-House',
  'Techno',
  'Deep-Techno',
  'Top40',
  'R&B',
  'Rap',
  'Hip-Hop',
]
export default genresData;